import { Maps } from 'js/components/Maps/index.js'
import { CobaltMap, MapSetup } from 'js/search/modules/LocatorCobaltMapGoogle.js';
import { Instance as MapsAPI, LibraryTypes as MapsLibraryTypes } from 'js/components/Maps/GoogleMapsAPI.js';
import { Autocompleter } from 'js/common/modules/Autocompleter.js';

export const SetupAutocomplete = () => {
  Maps.loadAndRun();
  window.Yext.Maps = { CobaltMap: CobaltMap};
  MapSetup();

  const locatorEl = document.querySelector('#js-locator');

  MapsAPI.addLibrary(MapsLibraryTypes.Places);
  MapsAPI.loaded().then(() => {
    new Autocompleter(document.getElementById('q'), locatorEl);
  });
  MapsAPI.load();
};
