import 'babel-polyfill';
import createFocusTrap from 'focus-trap/index.js';
import { PinClickEvent, PinHoverEvent, CardHoverEvent, CardClickEvent } from './Events.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
import { Maps } from 'js/components/Maps/index.js';
import { SearchMap } from 'js/search/modules/Google.js';

export class CobaltMap extends SearchMap {
  static initClass() {
    Maps.FactoryForProvider.Google = (data) => new this(data);
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.CobaltMap";
    this.breakPoint = 992;
  }

  constructor(args) {
    super(args);
    this.pinHeight = 48;
    this.pinWidth = 28;
    this.largePinHeight = 58;
    this.largePinWidth = 34;
    this.pins = [];
    this.defaultText = 'white';
    this.hoveredText = 'white';
    this.selectedText = '#141b4d';
    this.defaultColor = '#141b4d';
    this.hoveredColor = '#1c57a5';
    this.selectedColor = 'white';
    this.defaultBorder = '#12131e';
    this.hoveredBorder = '#12131e';
    this.selectedBorder = '#141b4d';
    this.eliteColor = '#0047bb';

    // PC-199589: Map Search override
    this.isMapSearch = (args.element.id === 'dir-map-mapsearch');
  }

  svgPinNoIndex(backgroundColor, textColor, borderColor, isBig) {
    if (isBig) {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="${this.largePinWidth}" height="${this.largePinHeight}" viewBox="0 0 ${this.largePinWidth} ${this.largePinHeight}">
          <g fill="none" fill-rule="evenodd">
              <path fill="${backgroundColor}" fill-rule="nonzero" d="M17 58c.814 0 1.223-.403 1.214-1.208C18.821 35.042 34 29 34 16.917 34 7.25 26.714 0 17 0S0 7.25 0 16.917C0 29 15.179 35.042 15.786 56.792c0 .798.4 1.208 1.214 1.208z"/>
              <path fill-rule="nonzero" stroke="${borderColor}" stroke-width="2" d="M17 57c.263 0 .217.046.215-.236.26-9.309 2.826-15.924 8.052-23.738.4-.6.81-1.199 1.352-1.984l.662-.956.646-.936c1.018-1.484 1.717-2.556 2.336-3.604 1.852-3.141 2.737-5.77 2.737-8.63C33 7.804 26.163 1 17 1S1 7.803 1 16.917c0 2.859.885 5.488 2.737 8.629.619 1.048 1.318 2.12 2.336 3.604l.646.936.662.956c.542.785.951 1.384 1.352 1.984 5.226 7.814 7.792 14.429 8.053 23.766 0 .25-.043.208.214.208z"/>
          </g>
      </svg>`;
    } else {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="${this.pinWidth}" height="${this.pinHeight}" viewBox="0 0 ${this.pinWidth} ${this.pinHeight}">
          <g fill="none" fill-rule="evenodd">
              <path fill="${backgroundColor}" d="M14 48c.67 0 1.007-.333 1-1 .5-18 13-23 13-33 0-8-6-14-14-14S0 6 0 14c0 10 12.5 15 13 33 0 .66.33 1 1 1z"/>
              <path stroke="${borderColor}" d="M14 47.5c.395 0 .504-.109.5-.514.212-7.633 2.304-13.052 6.576-19.47.33-.496.666-.99 1.111-1.639a901.059 901.059 0 0 1 1.077-1.567c.843-1.233 1.422-2.126 1.936-3.001 1.553-2.646 2.3-4.876 2.3-7.309C27.5 6.276 21.724.5 14 .5S.5 6.276.5 14c0 2.433.747 4.663 2.3 7.309.514.875 1.093 1.768 1.936 3.001a236.62 236.62 0 0 0 1.077 1.567c.445.648.782 1.143 1.111 1.638C11.196 33.935 13.288 39.353 13.5 47c0 .389.11.5.5.5z" opacity=".5"/>
          </g>
      </svg>`;
    }
  }

  svgPin(i, backgroundColor, textColor, borderColor, isBig) {
    if (isBig) {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="${this.largePinWidth}" height="${this.largePinHeight}" viewBox="0 0 ${this.largePinWidth} ${this.largePinHeight}">
          <g fill="none" fill-rule="evenodd">
              <path fill="${backgroundColor}" fill-rule="nonzero" d="M17 58c.814 0 1.223-.403 1.214-1.208C18.821 35.042 34 29 34 16.917 34 7.25 26.714 0 17 0S0 7.25 0 16.917C0 29 15.179 35.042 15.786 56.792c0 .798.4 1.208 1.214 1.208z"/>
              <path fill-rule="nonzero" stroke="${borderColor}" stroke-width="2" d="M17 57c.263 0 .217.046.215-.236.26-9.309 2.826-15.924 8.052-23.738.4-.6.81-1.199 1.352-1.984l.662-.956.646-.936c1.018-1.484 1.717-2.556 2.336-3.604 1.852-3.141 2.737-5.77 2.737-8.63C33 7.804 26.163 1 17 1S1 7.803 1 16.917c0 2.859.885 5.488 2.737 8.629.619 1.048 1.318 2.12 2.336 3.604l.646.936.662.956c.542.785.951 1.384 1.352 1.984 5.226 7.814 7.792 14.429 8.053 23.766 0 .25-.043.208.214.208z"/>
              <text fill="${textColor}" font-family="Arial, sans-serif" font-size="17" font-weight="bold">
                  <tspan x="50%" y="40%" text-anchor="middle">${i}</tspan>
              </text>
          </g>
      </svg>`;
    } else {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="${this.pinWidth}" height="${this.pinHeight}" viewBox="0 0 ${this.pinWidth} ${this.pinHeight}">
          <g fill="none" fill-rule="evenodd">
              <path fill="${backgroundColor}" d="M14 48c.67 0 1.007-.333 1-1 .5-18 13-23 13-33 0-8-6-14-14-14S0 6 0 14c0 10 12.5 15 13 33 0 .66.33 1 1 1z"/>
              <path stroke="${borderColor}" d="M14 47.5c.395 0 .504-.109.5-.514.212-7.633 2.304-13.052 6.576-19.47.33-.496.666-.99 1.111-1.639a901.059 901.059 0 0 1 1.077-1.567c.843-1.233 1.422-2.126 1.936-3.001 1.553-2.646 2.3-4.876 2.3-7.309C27.5 6.276 21.724.5 14 .5S.5 6.276.5 14c0 2.433.747 4.663 2.3 7.309.514.875 1.093 1.768 1.936 3.001a236.62 236.62 0 0 0 1.077 1.567c.445.648.782 1.143 1.111 1.638C11.196 33.935 13.288 39.353 13.5 47c0 .389.11.5.5.5z" opacity=".5"/>
              <text fill="${textColor}" font-family="Arial,sans-serif" font-size="13" font-weight="medium">
                  <tspan x="50%" y="40%" text-anchor="middle">${i}</tspan>
              </text>
          </g>
      </svg>`;
    }
  }

  iconImage(loc, i, backgroundColor, textColor, borderColor, isBig) {
    const isElite = loc.customByName ? loc.customByName['Agency Type'] === 'Elite' : false;
    const isDefault = backgroundColor === this.defaultColor;
    const pinColor = isElite && isDefault ? this.eliteColor : backgroundColor;
    let iconUrl = `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPin(i+1, pinColor, textColor, borderColor, isBig))}`;
    //PC-213299 we want map pins to have no indices on page load for map search
    if (!document.querySelector('.has-results')) {
      iconUrl = `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPinNoIndex(pinColor, textColor, borderColor, isBig))}`;
    }
    const size = isBig ? [this.largePinWidth, this.largePinHeight] : [this.pinWidth, this.pinHeight];
    return {
      url: iconUrl,
      scaledSize: new google.maps.Size(...size)
    };
  }

  registerResultHandlers() {
    registerResultHandlers();
  }

  removePins() {
    for (let pin of this.pins) {
      pin.setMap(null);
    }
    this.pins = [];
  }

  updateMap() {
    this.boundsForPins = new google.maps.LatLngBounds();
    this.infowindow = new google.maps.InfoWindow();

    this.pins = [];
    let idx = 0;

    for (let location of Array.from(this.allLocations)) {
      let pin = this.preparePin(idx, location, this.map);
      this.boundsForPins.extend(pin.position);
      this.pins.push(pin);
      idx++;
    }
    this.redrawMap();
  }

  prepareMap() {
    const map = super.prepareMap();
    this.constructor.map = map;
    return map;
  }

  setMapBounds() {
    if (!(this.allLocations.length > 0)) { return; }
    let offset = this.mapOffset();
    this.updateMapDimensions();
    if (!(this.mapDimensions.height > 0) || !(this.mapDimensions.width > 0)) { return; }
    let bounds = this.boundsForPins;
    let dimensions = {
      width: this.mapDimensions.width - offset.left - offset.right,
      height: this.mapDimensions.height - offset.top - offset.bottom
    };

    let zoomLevel = this.allLocations.length > 1 ? this.getBoundsZoomLevel(bounds, dimensions) : 16;
    this.map.setZoom(zoomLevel);

    this.setOffsetCenter(bounds.getCenter(), offset);
  }

  stylePin(pin) {
    let icon = this.iconImage(loc, i, this.defaultColor, this.defaultText, this.defaultBorder);
    if (pin.isSelected) {
      icon = this.iconImage(loc, i, this.selectedColor, this.selectedText, this.selectedBorder, true);
      pin.setZIndex(1);
    } else if (pin.isHovered) {
      icon = this.iconImage(loc, i, this.hoveredColor, this.hoveredText, this.hoveredBorder);
      pin.setZIndex(1);
    } else {
      pin.setZIndex(0);
    }
    this.validatePinIcon(icon);
    pin.setIcon(icon);
  }

  preparePin(i, loc, m) {
    let icon = this.iconImage(loc, i, this.defaultColor, this.defaultText, this.defaultBorder);
    this.validatePinIcon(icon);
    const position = new google.maps.LatLng(loc.latitude, loc.longitude);
    let pin = new google.maps.Marker({
      position: position,
      icon,
      map: m,
      zIndex: 0,
      optimized: false // For IE <= 11 compat
    });

    pin.addListener('click', () => {
      if (Yext.Analytics) {
        Yext.Analytics.send({eventType: 'map_pin_click'});
      }
      this.clickHandler(loc, pin, i, m);
    });

    this.pins.push(pin);
    pin.isHovered = false;
    pin.isSelected = false;

    pin.addListener('mouseover', () => {
      this.hoverHandler(true, loc, pin, i, m);
    });
    pin.addListener('mouseout', () => {
      this.hoverHandler(false, loc, pin, i, m);
    })

    //PC-214047: when the map search capital pins are clicked, trigger a search at the
    //pin's location
    document.addEventListener(PinClickEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget && !document.URL.includes('?q=') && !document.querySelector('.has-results')) {
        let inputEl = document.getElementById('q');
        let formEl = document.getElementById('search-form');
        if (inputEl && formEl && loc.cityState) {
          inputEl.value = loc.cityState;
          formEl.dispatchEvent(new Event('submit'));
        }
      }
    });

    document.addEventListener(CardClickEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget) {
        const bounds = m.getBounds();
        if (!bounds.contains(position)) {
          m.panTo(position);
        }
        let icon = this.iconImage(loc, i, this.selectedColor, this.selectedText, this.selectedBorder, true);
        this.validatePinIcon(icon);
        pin.setZIndex(1);
        pin.setIcon(icon);
        pin.isSelected = true;
      } else {
        let icon = this.iconImage(loc, i, this.defaultColor, this.defaultText, this.defaultBorder);
        this.validatePinIcon(icon);
        pin.setZIndex(0);
        pin.setIcon(icon);
        pin.isSelected = false;
        pin.isHovered = false;
      }
    });

    document.addEventListener(PinClickEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (event.detail.yextId == myTarget) {
        let icon = this.iconImage(loc, i, this.selectedColor, this.selectedText, this.selectedBorder, true);
        this.validatePinIcon(icon);
        pin.setZIndex(1);
        pin.setIcon(icon);
        pin.isSelected = true;
      } else {
        let icon = this.iconImage(loc, i, this.defaultColor, this.defaultText, this.selectedText);
        this.validatePinIcon(icon);
        pin.setZIndex(0);
        pin.setIcon(icon);
        pin.isSelected = false;
        pin.isHovered = false;
      }
    });

    document.addEventListener(CardHoverEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (pin.isSelected && !this.isMapSearch) {
        pin.isHovered = event.detail.active;
        return;
      }

      if (event.detail.yextId == myTarget && event.detail.active) {
        let icon = this.iconImage(loc, i, this.hoveredColor, this.hoveredText, this.hoveredBorder);
        this.validatePinIcon(icon);
        pin.setZIndex(1);
        pin.setIcon(icon);
        pin.isHovered = true;
      } else {
        let icon = this.iconImage(loc, i, this.defaultColor, this.defaultText, this.defaultBorder);
        this.validatePinIcon(icon);
        pin.setZIndex(0);
        pin.setIcon(icon);
        pin.isHovered = false;
      }
    });

    document.addEventListener(PinHoverEvent.eventTypeName, (event) => {
      let myTarget = `js-yl-${loc.id}`;
      if (pin.isSelected && !this.isMapSearch) {
        pin.isHovered = event.detail.active;
        return;
      }
      if (event.detail.yextId == myTarget && event.detail.active) {
        let icon = this.iconImage(loc, i, this.hoveredColor, this.hoveredText, this.hoveredBorder);
        this.validatePinIcon(icon);
        pin.setZIndex(1);
        pin.setIcon(icon);
        pin.isHovered = true;
      } else {
        let icon = this.iconImage(loc, i, this.defaultColor, this.defaultText, this.defaultBorder);
        this.validatePinIcon(icon);
        pin.setZIndex(0);
        pin.setIcon(icon);
        pin.isHovered = false;
      }
    });

    return pin;
  }
}
CobaltMap.initClass();

const registerResultHandlers = () => {
  let results = document.getElementsByClassName('js-location-result');
  for (let result of Array.from(results)) {
    result.addEventListener('click', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.CobaltMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardClickEvent(this.getAttribute('id')));
    });
    result.addEventListener('mouseover', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.CobaltMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardHoverEvent(this.getAttribute('id'), true));
    });
    result.addEventListener('mouseout', function() {
      const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.CobaltMap.breakPoint}px)`);
      if (!minWidthSearchMapBreakPointQuery.matches) { return; }
      document.dispatchEvent(new CardHoverEvent(this.getAttribute('id'), false));
    });
  }
}

const registerDocumentHandlers = () => {
  document.addEventListener(PinClickEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    if (id == null) { return; }
    const minWidthSearchMapBreakPointQuery = window.matchMedia(`(min-width: ${Yext.Maps.CobaltMap.breakPoint}px)`);
    if (minWidthSearchMapBreakPointQuery.matches) {
      let idEl = document.getElementById(id);
      if (idEl) {
        idEl.classList.add('is-selected');
      }
    }
    let toRemove = document.querySelectorAll(`.js-location-result.is-selected:not(#${id})`);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-selected');
      toChange.classList.remove('is-hovered');
    }

    const stickyHeight = document.querySelector('.js-search-box').offsetHeight;

    const targetEl = document.getElementById(id);

    function isScrolledIntoView(elem) {
      const elBottom = $(elem).position().top + elem.offsetHeight;
      return (elBottom <= (window.scrollY + window.innerHeight)) && ($(elem).position().top >= (window.scrollY + stickyHeight));
    }

    if (targetEl && !isScrolledIntoView(targetEl)) {
      $('html ,body').animate({
        scrollTop: $(targetEl).offset().top - document.querySelector('header').offsetHeight - stickyHeight,
      }, {duration: 600, queue: false});
    }
  });

  document.addEventListener(CardClickEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    if (!id) { return; }
    const targetEl = document.getElementById(id);
    let selector = `.js-location-result.is-selected`;
    let toRemove = document.querySelectorAll(selector);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-selected');
    }
    targetEl.classList.add('is-selected');
  });

  document.addEventListener(PinHoverEvent.eventTypeName, function(e) {
    let id = e.detail.yextId;
    const targetEl = document.getElementById(id);
    if (targetEl && e.detail.active) {
      targetEl.classList.add('is-hovered');
    }
    let selector = `.js-location-result.is-hovered`;
    if (e.detail.active) {
      selector += `:not(#${id})`;
    }
    let toRemove = document.querySelectorAll(selector);
    for (let toChange of Array.from(toRemove)) {
      toChange.classList.remove('is-hovered');
    }
  });
}

export const MapSetup = () => {
  registerResultHandlers();
  registerDocumentHandlers();
}
