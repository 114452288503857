import 'babel-polyfill';
import 'script-loader!node_modules/jquery/dist/jquery.min.js';

// uncomment if you need to do expand/collapse:
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { Yext } from 'js/components/Soy2js/Soy2js.js'; // NOTE: if not using soy2js, replace with window.Yext = window.Yext || {};

import { Cookies } from 'js/components/Cookies/index.js';
import { GoogleAdwords } from 'js/components/GoogleAdwords/index.js';
import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js';
import { Image } from 'js/components/Image/index-override.js';

import { SearchBoxInit } from 'js/common/modules/SearchBox.js';
import { SetUpGeolocation } from 'js/directory/modules/DirectoryGeolocation.js';
import { SetupAutocomplete } from 'js/directory/modules/AutocompleteSetup.js';
import { BackToTop } from 'js/common/modules/BackToTop';


Global.init();
GoogleAdwords.init();
GoogleAnalytics.enableAutotracking('yext');

OnReady(() => {
  SearchBoxInit();
  SetUpGeolocation();
  SetupAutocomplete();
  new BackToTop();

  // - The un-minified file is different than the minified one :/
  // - Uncomment the following if using JVector map
  // VectorMap.autorun();
});
